import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleClickEvent } from '../../utils';

function Header({withEffect=true}) {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if (!withEffect) {
            setIsScrolled(true);
            return
        }
        const handleScroll = () => {
        const scrollThreshold = 50; // You can adjust this value as needed
        if (window.scrollY > scrollThreshold) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerClasses = isScrolled ? 'fixed inset-x-0 top-0 z-50 scrolled' : 'fixed inset-x-0 top-0 z-50';

    return(<>
        <header className={headerClasses}>
            <div className='w-[250px] flex justify-center items-center m-auto'>
                <Link to="/" className="block">
                    <img ga-event-name="headerLogoClick" onClick={(e) => {handleClickEvent(e)}} src="/images/logo.png" alt='FlashMedia' className='logo' />
                </Link>
            </div>
            
        </header>
    </>)
}


export default Header;