import React, { useState, useEffect } from 'react';
import { Navigation, Pagination, Autoplay  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { Tab } from '@headlessui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from '../../components/Layout';
import { sendManageUpClient } from "../../utils"
import { Helmet } from 'react-helmet';
import ClipLoader from "react-spinners/ClipLoader";
import { handleClickEvent } from "../../utils";




const validationSchema = yup.object({
    name: yup
      .string('Enter your Name')
      .required('Name is required'),
    email: yup
      .string('Enter your Email')
      .email('Invalid email')
      .required('Email is required')
});


function Home() {
    const textArray = ['לידים', 'מיתוג', 'מכירות', 'עוקבים', 'ביקוש', 'בידול', 'כסף!'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0)
    let [loading, setLoading] = useState(false);



    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(()=>{
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % textArray.length);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [textArray.length])

    const formik = useFormik({
        // enableReinitialize:{true: true,},
        initialValues: {
            name: '',
            email: '',
            phone: '',
            comments: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            try{
                window.gtag('event', 'formSubmit', {});
            }catch(e){
                console.log(e)
            }
            setLoading(true)
            try {
                sendManageUpClient(values.name, values.email, values.phone, values.comments)
            } catch (err) {
                try{
                    window.gtag('event', 'failedAddToManageUp', {});
                }catch(e){
                    console.log(e)
                }
                console.error(err);
            }
            fetch(process.env.REACT_APP_API_GATEWAY_INVOKE_URL, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response.status === 200) {
                    try{
                        window.gtag('event', 'formSubmitSuccess', {});
                    }catch(e){
                        console.log(e)
                    }
                    resetForm();
                    Swal.fire({
                        icon: "success",
                        title: "הפרטים נשלחו בהצלחה, ניצור איתך קשר בקרוב !",
                        showConfirmButton: true,
                    });
                } else {
                    try{
                        window.gtag('event', 'formSubmitFail', {
                            type: "1"
                        });
                        
                    }catch(e){
                        console.log(e)
                    }
                    Swal.fire({
                        icon: "error",
                        title: "הייתה בעיה בשליחת הפרטים, תנסו מאוחר יותר ...",
                        showConfirmButton: true,
                    });
                }
                setLoading(false)
                return
            })
            .catch(error => {
                // Handle errors
                try{
                    window.gtag('event', 'formSubmitFail', {
                        type: "2"
                    });
                }catch(e){
                    console.log(e)
                }
                console.error('There was an error!', error);
                Swal.fire({
                    icon: "error",
                    title: "הייתה בעיה בשליחת הפרטים, תנסו מאוחר יותר ...",
                    showConfirmButton: true,
                });
                setLoading(false)
            });

        }
    });

    function handleScroll(event) {
        event.preventDefault(); // Prevent the default anchor behavior
    
        const targetId = event.target.getAttribute('href'); // Get the href attribute
        const targetElement = document.querySelector(targetId); // Find the target element
    
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth' // Scroll behavior set to smooth
            });
        }
    }

    function handleSocialClick(index) {
        const socialLinkArr = [
            {
                "link": "https://www.instagram.com/idanbenbasat19/",
                "name": "idan"
            },
            {
                "link": "https://www.tiktok.com/@itay_and_roei",
                "name": "tiktok"
            },
            {
                "link": "https://www.instagram.com/royberguig",
                "name": "roy"
            },
            
        ]
    
        try{
            window.gtag('event', 'social_click', {
              socialName: socialLinkArr[index]["name"]
            });
        }catch(e){
            console.log(e)
        }
        

        const socialLink = socialLinkArr[index]["link"]
        window.open(socialLink)
    }

    

    return(<Layout>
            <Helmet>
                <title>פלאש מדיה - עמוד הבית</title>
                <meta
                    name="description"
                    content="ניהול סושיאל לעסקים ואנשים פרטיים. מנווטים אתכם בג׳ונגל של המדיה!"
                />
            </Helmet>
    <div className="bg-main">
        <div className="relative flex flex-col justify-center items-center isolate px-6 pt-14 lg:px-8 banner-area-main">
            <img className='absolute top-0 z-0 opacity-35 w-full lg:hidden' src='images/jungleTOPBG.png' alt='jungle-bg'/>
            <div className="mx-auto max-w-2xl lg:max-w-5xl py-10 sm:py-15 lg:py-20 relative z-10">
                <div className="text-center">
                    <h1 className="mb-6 text-5xl font-bold tracking-tight text-white sm:text-6xl" data-aos="fade-up" data-aos-duration="1500">
                        פלאש מדיה
                    </h1>
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl" data-aos="fade-up" data-aos-duration="1500">
                    מנווטים אותך בג׳ונגל של המדיה
                    </h1>
                    {/* <h1 className="mt-6 text-4xl font-bold tracking-tight text-white sm:text-6xl" data-aos="fade-up" data-aos-duration="2000">
                        שמייצרת לך
                        <span class="text-skyblue-10 mr-3">{textArray[currentIndex]}</span>
                    </h1> */}
                    <p className="mt-4 text-lg leading-8 text-gray-300 font-bold drop-shadow-2xl" data-aos="fade-up" data-aos-duration="1500">
                        <br />
                        אז אחרי 4 שנים בתחום ואחרי שצברנו מעל ל 500,000 עוקבים בכל הפלטפורמות, החלטנו לקחת את זה צעד אחד קדימה.
                        עם מחסניות של הרבה מאוד ידע וערימות של מוטיבציה, החלטנו שהאתגר הבא שלנו הוא להפוך עסקים למפלצות של תוכן ברשתות.
                        אז אנחנו מזהירים אתכם! זה לא יהיה קל וגם לא יהיה זול, כי על איכות צריך לשלם.
                        אבל ביחד אנחנו נהפוך את העסק שלכם לדבר 

                        <br />
                        <b>הכי חם היום ברשת!</b>
                    </p>
                    <div onClick={handleScroll} className="mt-10 flex items-center justify-center gap-x-6" data-aos="fade-up" data-aos-duration="2000">
                    <a ga-event-name="heroCTAClick" onClick={(e) => {handleClickEvent(e)}} href="#contact-us-section" className="flex flex-row gap-[10px] items-center rounded-full px-3.5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-skyblue-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style={{ background: 'linear-gradient(90deg, #28CCBD, #508F89, #347A8A)', border: '2px solid white'}}>
                        יאללה בואו נתחיל <img className='h-[40px] w-[40px]' src="images/logoWIthoutBG.png" />
                    </a>



                    </div>
                </div>
            </div>
            <img className='absolute bottom-0 z-0 opacity-35 w-full' src='images/jungleBOTTOMBG.png' alt='jungle-bg'/>
        </div>
    </div>



    <div className='customer-logos-main py-20'>
        <div className="container mx-auto">
            <h1 className="text-4xl font-bold tracking-tight text-skyblue-10 sm:text-4xl text-center pb-10" data-aos="fade-down" data-aos-duration="1500">
                עסקים שעובדים איתנו {";)"}
            </h1>

            <div className='customer-slider-main w-[60%] max-md:w-full flex justify-center items-center m-auto' data-aos="fade-up" data-aos-duration="1500">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={2}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true,
                        }}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        onSwiper={(swiper) => {}}
                    >
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-5.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>עמית בוצ׳אצ׳ו</p>
                            <p>טארוט</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-2.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>נתנאל גרופי</p>
                            <p>טועם ממתקים</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-3.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>גונן טל</p>
                            <p>מרצה, הלום קרב</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img className='rounded-full' src='/images/c-logo-6.jpg' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>ליגת ווינרסל</p>
                            <p>מנהלת הליגה בכדורסל</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-4.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>השווארמה של רונן</p>
                            <p>שווארמיה</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-1.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>מתן עמר</p>
                            <p>ספר</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='company-logo-box'>
                            <img src='/images/c-logo-7.png' alt='Company Logo' />
                        </div>
                        <div className='text-center text-[13px]'>
                            <p>מורן אמיתי</p>
                            <p>יועץ משכתנתאות</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    </div>


    <div className='customer-logos-main sm:py-20'>
        <div className="container mx-auto text-center">
            <h1 className="text-4xl font-bold tracking-tight text-skyblue-10 sm:text-4xl text-center pb-6" data-aos="fade-down" data-aos-duration="1500">
                קצת עלינו
            </h1>
            <p className='text-lg leading-8 text-gray-700 font-bold drop-shadow-2xl' data-aos="fade-up" data-aos-duration="1500">
            אז איך הכל התחיל אתם שואלים?<br/>
            אני יודע שלא שאלתם, אבל תקראו כי זה מעניין.<br/>
            אנחנו עידן בן בסט ורוי ברגיג, הכרנו בצבא, שם פיקדנו על צוות לוחמים כי אנחנו גברים רצח.<br/>
            כבר במהלך השירות התחלנו לעשות סרטונים ביחד עם הצוות שלנו כשאנחנו שרים ברכב בשביל הגיבוש והצחוקים.<br/>
            אחרי תקופה קצרה עזבנו את הצוות כשהמשכנו לתפקידים שונים, פתחנו טיקטוק מחדש, רק שלנו, ושם הכל כבר התחיל להתפוצץ.<br/>
            סרטונים שרובכם כבר מכירים עם דמויות כמו איתי ורועי, אפריים וכו, התפוצצו והגיעו למליוני צפיות.<br/>
            והיום החלטנו שאנחנו מאתגרים את עצמינו להיכנס לעולם השיווק לעסקים.<br/>
            המטרה העיקרית שלנו היא להראות לעסקים שפלטפורמות המדיה היום הן הכלי מספר אחת לשיווק.
            </p>
            <div className="flex justify-center">
                <div className='profile-images w-full flex justify-center'>
                    <div className='flex flex-row justify-around items-center max-w-[600px] w-full mb-[20px]'>
                        <div ga-event-name="socialIdanClick" onClick={(e) => {handleClickEvent(e);handleSocialClick(0)}} className='text-center text-[13px] cursor-pointer' data-aos="fade-up" data-aos-duration="2500">
                            <img className='!w-[85px] h-[85px] md:h-[100px] md:!w-[100px] rounded-full' src='/images/benbasatLogo.jpg' alt='Company Logo' />
                            <p>עידן בן בסט</p>
                            <p>אינסטגרם</p>
                        </div>
                        <div ga-event-name="socialTiktokClick" onClick={(e) => {handleClickEvent(e);handleSocialClick(1)}} className='text-center text-[13px] cursor-pointer' data-aos="fade-up" data-aos-duration="2000">
                            <img className='!w-[85px] h-[85px] md:h-[100px] md:!w-[100px] rounded-full' src='/images/itayandroeiLogo.jpeg' alt='Company Logo' />
                            <p>איתי ורועי</p>
                            <p>עמוד טיקטוק</p>
                        </div>
                        <div ga-event-name="socialRoyClick" onClick={(e) => {handleClickEvent(e);handleSocialClick(2)}} className='text-center text-[13px] cursor-pointer' data-aos="fade-up" data-aos-duration="1500">
                            <img className='!w-[85px] h-[85px] md:h-[100px] md:!w-[100px] rounded-full' src='/images/royLogo.jpeg' alt='Company Logo' />
                            <p>רוי ברגיג</p>
                            <p>אינסטגרם</p>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div className='how-its-work-section pb-20'>
        <div className="container mx-auto">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl text-center pb-10" data-aos="fade-down" data-aos-duration="1500">
                איך זה עובד?
            </h1>

            <div className='columns-1'>
                <div className='how-work-box' data-aos="fade-up" data-aos-duration="2000">
                    <div className='how-work-icon'>
                        <img src='images/envelope-1.gif' className="h-9 w-9" aria-hidden="true" alt='more-info' />
                    </div>
                    <div className='how-work-content'>
                        <h3>שלב 1 – "פרטים נוספים"</h3>
                        <p className='font-bold drop-shadow-2xl'>
                            בואו נסיר דאגות מהלב, לבקש פרטים לא עולה כסף.<br />
                            השלב הראשון הוא בסך הכל לשלוח הודעה ולהתעניין.
                        </p>
                    </div>
                </div>
                <div className='how-work-box' data-aos="fade-up" data-aos-duration="1500">
                    <div className='how-work-icon'>
                        <img src='images/phone-2.gif' className="h-9 w-9" aria-hidden="true" alt='phone-info' />
                    </div>
                    <div className='how-work-content'>
                        <h3>שלב 2 – "שיחת אפיון"</h3>
                        <p className='font-bold drop-shadow-2xl'>
                            אז אחרי שביצענו את הקשר הראשוני, אנחנו נעשה לכם שיחת אפיון קצרה לגבי העסק. <br/>
                            נבין את כל הפרטים שדרושים לנו על מנת להכין לכם חבילה שלדעתנו תוכל לקחת את העסק שלכם כמה צעדים קדימה בעולם המדיה. <br/>
                            אל תדאגו, גם השלב הזה עדיין לא עולה כסף.

                        </p>
                    </div>
                </div>
                <div className='how-work-box' data-aos="fade-up" data-aos-duration="1000">
                    <div className='how-work-icon'>
                        <img src='images/coin-3.gif' className="h-9 w-9" aria-hidden="true" alt='coin-info' />
                    </div>
                    <div className='how-work-content'>
                        <h3>שלב 3 – "כאבים בכיס"</h3>
                        <p className='font-bold drop-shadow-2xl'>
                            השלב הזה כן עולה כסף. <br />
                            אז אחרי שיחת האפיון שנעשה, ואחרי בניית התוכנית לעסק שלכם, אנחנו ניתן לכם הצעת מחיר. <br />
                            אתם כמובן תתמקחו ותבקשו הנחה ואנחנו כמובן לא נסכים אלא אם כן תהיו ממש חמודים ואז אולי נוריד לכם איזה 100 שקל.
                        </p>
                    </div>
                </div>
                <div className='how-work-box' data-aos="fade-up" data-aos-duration="500">
                    <div className='how-work-icon'>
                        <img src='images/graph-4.gif' className="h-9 w-9" aria-hidden="true" alt='graph-info' />
                    </div>
                    <div className='how-work-content'>
                        <h3>שלב 4 – "להפוך למפלצות"</h3>
                        <p className='font-bold drop-shadow-2xl'>
                            אחרי שנפלתם בקסמים שלנו ושילמתם, אתם תכירו חזירי עבודה. מתחילים לעבוד! <br />
                            השלבים הם מאוד פשוטים: <br />
                            אנחנו כותבים תוכן -{'>'} מאשרים מולכם -{'>'} קובעים יום צילום -{'>'} אנחנו מצלמים ועורכים -{'>'} אנחנו מעלים לכם את התוכן לרשתות במהלך החודש. <br />
                            זהו, אתם עשירים! <br />
                            סתם עוד לא, יש דגש אחרון וחשוב מאוד. <br />
                            צריך להבין שהמדיה זה לא קסם, גם הדברים האלה הם תהליך שלוקח זמן, לפעמים לוקח שבוע ולפעמים 3 חודשים. <br />
                            אבל דבר אחד בטוח, שבסוף זה עובד!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className='how-its-work-section pb-20'>
        <div className="container mx-auto !pb-[50px]" data-aos="fade-down" data-aos-duration="1500">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl text-center pb-10">
                תיק עבודות
            </h1>
            <div className='h-[730px]'>
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex} data-aos="fade-up" data-aos-duration="1500">
                    <Tab.List>
                        <div className='portfolio-slider-main text-center'>
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={50}
                                centeredSlides={true}
                                slidesPerView={4}
                                initialSlide={3}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    400:{
                                        slidesPerView:2,
                                    },
                                    639: {
                                        slidesPerView: 2
                                    },
                                    865:{
                                        slidesPerView:2
                                    },
                                    1000:{
                                        slidesPerView:3
                                    },
                                    1500:{
                                        slidesPerView:4
                                    },
                                    1700:{
                                        slidesPerView:4
                                    }
                                }}
                                navigation
                                onSlideChange={(swiper) => {
                                    setSelectedIndex(swiper.activeIndex)
                                }}
                                onSwiper={(swiper) => {}}
                            >
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-5.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-2.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-3.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img className='rounded-full' src='/images/c-logo-6.jpg' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-4.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-1.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Tab>
                                        <div className='company-logo-box'>
                                            <img src='/images/c-logo-7.png' alt='Company Logo' />
                                        </div>
                                    </Tab>
                                </SwiperSlide>
                            </Swiper>
                            <Tab.Panels>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>מייקאובר לאינסטגרם</h3>
                                                    <img className='w-[260px]' src='/images/c-5-description.png' alt="amit-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>מייקאובר לאינסטגרם</h3>
                                                    <img className='w-[260px]' src='/images/c-2-description.png' alt="netanel-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>גונן טל סרטון לפורים</h3>
                                                    <img className='w-[260px]' src='/images/c-3-description.png' alt="gonen-details"/>
                                                </div>
                                            </SwiperSlide>

                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>אתגר בדיחות קרש עם עירוני נס ציונה</h3>
                                                    <img className='w-[260px]' src='/images/c-6-description-1.png' alt="winner-1-details"/>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>ראיון עם הקהל - איתי</h3>
                                                    <img className='w-[260px]' src='/images/c-6-description-2.png' alt="winner-2-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>יצא 38 ועוד קצת</h3>
                                                    <img className='w-[260px]' src='/images/c-4-description.png' alt="shawarma-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>מה שחשוב זאת הדרך</h3>
                                                    <img className='w-[260px]' src='/images/c-1-description.png' alt="sapar-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            navigation
                                            pagination={{ clickable: true }}
                                        >
                                            <SwiperSlide>
                                                <div className='portfolio-content-main'>
                                                    <h3>מייקאובר באינסטגרם</h3>
                                                    <img className='w-[260px]' src='/images/c-7-description.png' alt="mashkanta-details"/>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </Tab.Panel>
                            </Tab.Panels>
                        </div>
                    </Tab.List>
                </Tab.Group>
            </div>
        </div>
    </div>


    <div className='contact-section py-20' id="contact-us-section">
        <div className="container w-full mx-auto">
            <div className='p-[50px] bg-skyblue-300 rounded-[30px] max-md:p-[15px] max-md:pt-[20px] flex flex-row max-md:flex-col'>
                <div className='mb-10 w-[50%] max-md:w-full' data-aos="fade-down" data-aos-duration="1500">
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl pb-5 max-md:text-center">
                        צרו קשר
                    </h1>
                    <form className='w-[80%] max-md:w-full' onSubmit={formik.handleSubmit}>
                        <div className="mt-3 max-md:mt-6">
                            <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            placeholder='שם מלא'
                            className="block w-full rounded-md border-0 py-3 text-white bg-skyblue-100 font-bold shadow-sm ring-1 ring-inset ring-skyblue-20 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-white sm:leading-6"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            required
                            />
                        </div>
                        <div className="mt-3 max-md:mt-6">
                            <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            placeholder='אימייל'
                            className="block w-full rounded-md border-0 py-3 text-white bg-skyblue-100 font-bold shadow-sm ring-1 ring-inset ring-skyblue-20 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-white sm:leading-6"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            required
                           />
                        </div>
                        <div className="mt-3 max-md:mt-6">
                            <input
                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="phone"
                            placeholder='מספר טלפון'
                            className="block w-full rounded-md border-0 py-3 text-white bg-skyblue-100 font-bold shadow-sm ring-1 ring-inset ring-skyblue-20 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-white sm:leading-6"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            />
                        </div>
                        <div className="mb-6 mt-3 max-md:mt-6 max-md:mb-10">
                            <textarea
                            id="comments"
                            name="comments"
                            rows={4}
                            className="block w-full rounded-md border-0 py-3 text-white bg-skyblue-100 font-bold shadow-sm ring-1 ring-inset ring-skyblue-20 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-white sm:leading-6"
                            placeholder='ספרו לנו קצת עליכם'
                            maxLength={500}
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                           />
                        </div>
                        <div className='flex justify-end'>
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-full flex justify-center h-[48px] items-center rounded-full px-3.5 text-xl font-semibold text-white shadow-sm hover:bg-skyblue-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                style={{
                                background: loading ? 'transparent' : 'linear-gradient(90deg, #28CCBD, #508F89, #347A8A)',
                                border: loading ? '2px solid #28CCBD' : '', // Add border if loading
                                }}
                            >
                                {loading ? "" : "שלח"}
                                <ClipLoader 
                                    color="#28CCBD"
                                    loading={loading}
                                    size={25}
                                />
                            </button>
                            
                        </div>
                    
                    </form>
                </div>
                <div className='w-[50%] flex justify-center max-md:w-full' data-aos="fade-up" data-aos-duration="1500">
                    <img src="/images/rocket-contact.png" alt="Contact" />
                </div>
            </div>
        </div>
    </div>
    </Layout>)
}





export default Home;