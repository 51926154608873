import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <Layout withEffect={false}>
            <Helmet>
                <title>פלאש מדיה - תקנון האתר</title>
                <meta
                    name="description"
                    content="ניהול סושיאל לעסקים ואנשים פרטיים. מנווטים אתכם בג׳ונגל של המדיה!"
                />
            </Helmet>
            <div className="mx-auto max-w-3xl p-4 pt-[100px]">
                <h1 className="text-2xl text-center">תקנון האתר:</h1>
                <h2 className="font-bold text-xl">מבוא: </h2>
                <p><br></br></p>
                <p>ברוכים הבאים לאתר ׳׳פלאש מדיה׳׳ (להלן: &quot;האתר&quot;) </p>
                {/* המופעל על ידי ____________, ח.פ __________, מכתובת ____________ (להלן: &quot;הנהלת האתר&quot; ו/או &quot;החברה&quot;). */}
                <p>כל שימוש באתר מהווה הסכמה לתקנון זה, על התנאים וההגבלות הכלולים בו.</p>
                <p><br></br></p>
                <p>התקנון עלול להשתנות מעת לעת, ועל המשתמש להתעדכן בתקנון בכל כניסה לאתר. גלישה באתר ו/או שימוש בו מכל סוג, כמוהם כהסכמה לתנאי התקנון והתחייבות לפעול על פיהם. מובהר כי התקנון מהווה הסכם משפטית מחייבת לכל דבר ועניין ומחייב את המשתמש על כל שימושיו באתר.</p>
                <p><br></br></p>
                <ul>
                    <li>האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה.</li>
                    <li>השירותים באמצעות האתר כפופים לתנאים המפורטים בתקנון זה.</li>
                    <li>עצם הזמנת שירותים באתר תהווה הצהרה מצד הלקוח כי קרא את הוראות תקנון זה, הבין אותן והסכים להן. התקנון מהווה חוזה מחייב בין המשתמש לבין הנהלת האתר.</li>
                </ul>
                <p><br></br></p>
                <h2 className="font-bold text-xl">השירותים: </h2>
                <p><br></br></p>
                <ul>
                    <li>האתר מאפשר ניהול סושיאל לעסקים ואנשים פרטיים.</li>
                    <li>הנהלת האתר שומרת לעצמה את הזכות להפסיק את שירותיה בכל עת.</li>
                </ul>
                <p><br></br></p>
                <h2 className="font-bold text-xl">אבטחת מידע ופרטיות: </h2>
                <p><br></br></p>
                <ul>
                    <li>הנהלת האתר, כמו גם חברות אחיות וגורמי משנה נוספים הפועלים מטעם הנהלת החברה לאותם הסטנדרטים, רשאיות להשתמש במידע המופיע בטופס על מנת להביא לך את המידע והשירותים המבוקשים. מידע אישי זה לא ייחשף ולא ייעשה בו שימוש נוסף (למעט למטרות שיווקיות) ללא רשות ולא יועבר לצד שלישי ללא אישור מפורש מהלקוח.</li>
                    <li>תוכן השירות וחשיפת מידע אישי במהלך השירות, לעולם לא ייחשפו לאדם אחר או כל גורם ללא אישור בכתב מהלקוח. הנהלת האתר מחויבת לפרטיות שלך ופרטיות זו הינה בראש סדר העדיפויות שלנו.</li>
                    <li>החברה מתחייבת שלא לעשות שימוש במידע המסופק לה ע&quot;י הקונים אלא על מנת לאפשר את הרכישה באתר מכירות ובהתאם לכל דין.</li>
                    <li>האתר מאובטח באמצעות שימוש באמצעי אבטחה מתקדמים אשר מטרתם להבטיח שימוש תקין וגלישה בטוחה באתר וכן על מנת לשמור על פרטיות המשתמשים. כל המשתמש באתר ובשירותיו מתחייב כי לא יעשה כל פעילות שיש בה כדי לשבש את פעילות האתר, גניבת מידע על משתמשים ופריצה של מנגנוני האבטחה של האתר. </li>
                    <li>במקרה של שימוש לרעה, הנהלת האתר תפעל נגד כל פעילות בכל דרך חוקית אשר תעמוד לרשותה לרבות חסימת המשתמש מגישה לאתר ונקיטת הליכים משפטיים נגד המשתמש באם יפעל כאמור.</li>
                </ul>
                <p><br></br></p>
                <h2 className="font-bold text-xl">קניין רוחני: </h2>
                <p><br></br></p>
                <ul>
                    <li>כל זכויות הקניין הרוחני באתר זה הכוללים זכויות יוצרים, זכויות הפצה, סודות מסחריים, סימני המסחר וכל הקניין הרוחני מכל סוג שהוא, הן בנוגע לעיצוב ועמוד האתר, הן באשר לתכנים המופיעים בו הינן רכושה הבלעדי של הנהלת האתר.</li>
                    <li>אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר ו/או מהאתר, אלא אם ניתנה הסכמה לכך מראש ובכתב מטעם הנהלת האתר.</li>
                    <li>הלינק לסאונד הוא לינק לשימוש חד פעמי, מרגע לחיצה על הקישור והורדת הסאונד, הקישור הינו פג תוקף ולא ניתן לשימוש נוסף</li>
                </ul>
                <p><br></br></p>
                <h2 className="font-bold text-xl">דין וסמכות שיפוט: </h2>
                <p><br></br></p>
                <ul>
                    <li>פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך במחוז תל אביב-יפו בלבד תהא מסורה סמכות השיפוט הבלעדית.</li>
                </ul>
                <p><br></br></p>
                <h2 className="font-bold text-xl">יצירת קשר: </h2>
                <p><br></br></p>
                <ul>
                    <li>לכל שאלה ופנייה ניתן ליצור קשר עם הנהלת האתר במייל weflashmedia@gmail.com </li>
                </ul>
                <p><br></br></p>
                <p><br></br></p>
            </div>
        </Layout>
    );
}

export default TermsAndConditions;
