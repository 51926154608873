import Header from "../Header";
import Footer from "../Footer";
import ScrollToTop from "../../shared/ScrollToTop.jsx";


function Layout({ children, withEffect=true }) {
    return (
        <>
            <ScrollToTop />
            <Header withEffect={withEffect}/>
                {children}
            <Footer />
        </>
    );
}

export default Layout;
