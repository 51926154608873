
function generateUUID() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

export const sendManageUpClient = (name, email, phone, comments) => {
    

    let manageUpClient = {
      "user_id": "662d512bb2f95fabaa45f403",
      "clientCompanyNumber": "",
      "clientCompany": "",
      "clientEmail": email,
      "clientAddress": "ללא",
      "clientPhone": phone,
      "company_id": "",
      "clientName": name,
      "orders": []

    }
    

      const order = {
        "comment": comments,
        "remainder": "",
        "untildate": "",
        "status": "not-started",
        "paid": 0,
        "discount": 0,
        "orderName": name,
        "order_id":  generateUUID(),
        "created": (new Date()).toISOString(),
        "worker": "",
        "job_id": "",
        "attachFiles": [],
        "products": []

      }
      manageUpClient["orders"].push(order)
    
   
    fetch("https://www.manageup.co.il/api/a/client", {
      method: "POST",
      body: JSON.stringify(manageUpClient),
      headers: { "Content-Type": "application/json", Accept: "application/json", access_key: process.env.REACT_APP_MANAGE_UP_ACCESS_KEY },
    }).then((res) => {
      if (!res.ok) {
        console.log("Failed to add to ManageUp");
        return false;
      }
      return res.json();
    })
    .then((data) => {
      // Check if the response message is equal to "Client created"
      const success = data && data.message === "Client created";
      console.log(`Added to ManageUp: ${success}`);
      return success;
    })
    .catch((error) => {
      console.error("Error:", error);
      return false;
    }); 

  };

export function handleClickEvent(e) {
        
    try{
        const eventName = e.target.getAttribute('ga-event-name');
        console.log(eventName)
        window.gtag('event', eventName, {});
    }catch(e){
        console.log(e)
    }
  }