import React from "react";
import { Link } from "react-router-dom";
import { handleClickEvent } from "../../utils";

function Footer() {
  const clickSidoTech = () => {
    try {
      window.gtag("event", "sidotechClick", {});
    } catch (e) {
      console.log(e);
    }
    window.open("https://www.sidotech.org?utm_source=flashmedia");
  };

  return (
    <>
      <footer>
        <Link
          ga-event-name="tcClick"
          onClick={(e) => {
            handleClickEvent(e);
          }}
          to="/terms-and-conditions">
          תקנון האתר
        </Link>
      </footer>
      <div
        onClick={clickSidoTech}
        className="flex flex-row gap-4 justify-center items-center border-t-2 border-black p-1 cursor-pointer">
        <span className="text-sm">בית תוכנה:</span>
        <img className="h-[25px]" src="images/sidotech-logo.png" alt="sidotech-logo" />
      </div>
    </>
  );
}

export default Footer;
